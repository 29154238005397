<template>
    <div class="Speech">
        <h1>{{displayTitle}}
        <button class="icon" @click="toggle" aria-label="Voorlezen">
            <icon v-if="idle" name="volume-up" scale="1.5" />
            <icon v-else name="volume-off" scale="1.5" />
        </button>
        </h1>
        <div class="p" v-rich="finalBody"></div>
    </div>
</template>
<script>

import 'vue-awesome/icons/volume-up';
import 'vue-awesome/icons/volume-off';

export default {
    props: [
        'title',
        'body',
        'personalTitle',
        'replaceBody',
    ],
    mounted() {
        document.title = `${this.title} | WERK-portal.nl`;
    },
	destroyed() {
		this.stopAudio();
	},
    computed: {
        displayTitle() {
            if(this.personalTitle) { return this.personalTitle; }
            return this.title;
        },
        finalBody() {
            if(!this.replaceBody) { return this.body; }
            return this.replaceBody(this.body);
        },
    },
	methods: {
		toggle() {
			this.idle ? this.playAudio() : this.stopAudio();
		},
		playAudio() {

            const synth = window.speechSynthesis;
            const utterance = new SpeechSynthesisUtterance(`
            ${this.title}.

            ${this.body}
            `
                .replace(/<br\/?>/g, '\n')
                .replace(/<[^>]*>/g, '')
            );
            utterance.lang = 'nl-NL';
            utterance.rate = 0.75;
            utterance.voice = synth.getVoices().find((voice) => voice.lang === 'nl-NL')
            utterance.addEventListener('end', () => {
                this.stopAudio();
            });
            synth.speak(utterance);
			this.idle = false;
		},
		stopAudio() {
			this.idle = true;

			if(window.speechSynthesis)
            	window.speechSynthesis.cancel();
		},
	},
	data() {
		return {
			audio:null,
			idle:true,
		}
	},
}

</script>
<style lang="scss">

.Speech { 
    .icon {
        background: none;
        color: currentColor;
        margin-left: 6px;
        padding: 4px 8px;
        cursor: pointer;
        vertical-align: middle;
        .fa-icon {
        }
    }
    .p {
        margin-top: 16px;
    }
}

</style>
